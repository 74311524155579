import React from "react"

export default function HeaderCampaign(props) {
  return (
    <>
      <div
        className="cabecera-campaign"
        style={{ backgroundImage: `url(${props.imagen})` }}
      >
        <h1>{props.titulo}</h1>
      </div>
    </>
  )
}
